export const getIntro = async function(){
    const response = await fetch("https://aviadospproxy.azurewebsites.net/api/getIntro?");
    return response.json();
}
export const getSections = async function(){
    const response = await fetch("https://aviadospproxy.azurewebsites.net/api/getSections?");
    return response.json();
}
export const getEmployees = async function(){
    const response = await fetch("https://aviadospproxy.azurewebsites.net/api/getEmployees?");
    return response.json();
}
export const getServices = async function(){
    const response = await fetch("https://aviadospproxy.azurewebsites.net/api/getServices?");
    return response.json();
}
export const getContacts = async function(){
    const response = await fetch("https://aviadospproxy.azurewebsites.net/api/getContacts?");
    return response.json();
}