import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
// const newUrl ="https://www.aviado.no";
// let href = window.location.href.toLowerCase();
// if(href.indexOf("aviado.no")>-1 && window.location.protocol === 'http'){
//   window.location.replace(newUrl);
// }

// if((href.indexOf("www") < 0 && href.indexOf("aviado.no")>-1)){
//   window.location.replace(newUrl);
// }

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
