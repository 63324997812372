import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Contact } from '../models/models';
import { Phone, At, Envelope } from 'react-bootstrap-icons';
import { Parallax } from 'react-parallax';

export interface IContactCardProps {
  Contact: Contact;
  BgImage: string;
}

export interface IContactCardState {

}

export default class ContactCard extends React.Component<IContactCardProps, IContactCardState> {

  constructor(props: IContactCardProps) {
    super(props);
    this.state = {};
  }

  public render(): React.ReactElement<IContactCardProps> {
    return (
      <Parallax
        bgImage={this.props.BgImage}
        blur={{ min: -0, max: 0 }}
       
      >
        <div style={{ height: 1200 }} id="contact">
        <div id="Contact">
              <div className="Contacts">
              <Row>
                  <Col><h2>{this.props.Contact.Title}</h2></Col>
                </Row>
                <Row>
                  <Col><At /> {this.props.Contact.Email}</Col>
                </Row>
                <Row>
                  <Col><Phone /> {this.props.Contact.Phone}</Col>
                </Row>
                <Row><Col style={{"padding":"8px"}}></Col></Row>
                <Row>
                  <Col><Envelope /> {this.props.Contact.Street}</Col>
                </Row>
                <Row>
                  <Col>{this.props.Contact.PostalCode} {this.props.Contact.City}</Col>
                </Row>

                <Row>
                  <Col  style={{paddingBottom:'40px'}}>{this.props.Contact.OrgNumber}</Col>
                </Row>
              </div>
            </div>
        </div>
      </Parallax>
    );
  }

}