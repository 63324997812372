import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { Employee } from '../models/models';

export interface IEmployeeCardProps {
  Employee: Employee;
}

export interface IEmployeeCardState {

}

export default class EmployeeCard extends React.Component<IEmployeeCardProps, IEmployeeCardState> {

  constructor(props: IEmployeeCardProps) {
    super(props);
    this.state = {};
  }

  public render(): React.ReactElement<IEmployeeCardProps> {
    return (
      <Col xs={12} md={6} lg={6}>
        <Card className="employeeCard">
          <Card.Header><span className="employeeName">{this.props.Employee.Name}</span><span className="employeeTitle">{this.props.Employee.Title}</span></Card.Header>
          <Row>
            {/* <Col xs={3} md={4} lg={4}>
              <Card.Img variant="top" src={this.props.Employee.Image} className="employeeImage" />
            </Col> */}
            {/* <Col  xs={8} md={8} lg={8}> */}
            <Col>
              <Card.Body>
                <Row>
                  <Col xs={9} md={9} lg={9}>
                    <Card.Text className="employeeIntro">
                      <span dangerouslySetInnerHTML={{ __html: this.props.Employee.Intro }} />
                    </Card.Text>
                  </Col>
                  <Col xs={3} md={3} lg={3}>
                    <Card.Img variant="top" src={this.props.Employee.Image} className="employeeImage" />
                  </Col>
                </Row>
                <Row>
                  <Col>
                  <Card.Text className="employeeDescription">
                    <span dangerouslySetInnerHTML={{ __html: this.props.Employee.Description }} />
                  </Card.Text>
                  </Col>
                </Row>
              </Card.Body>
            </Col>
          </Row>
          <Card.Footer><div style={{float:"left"}}>{this.props.Employee.Email}</div><div style={{float:"right"}}>{this.props.Employee.Phone}</div></Card.Footer>
        </Card>
      </Col>
    );
  }

}