import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { Service } from '../models/models';


export interface IServiceCardProps {
  Service: Service;
}

export interface IServiceCardState {

}

export default class ServiceCard extends React.Component<IServiceCardProps, IServiceCardState> {

  constructor(props: IServiceCardProps) {
    super(props);
    this.state = {};
  }

  public render(): React.ReactElement<IServiceCardProps> {
    return (
      <Col xs={12} md={4} lg={4} className="serviceCard">
        <Card className="shadow">
          <Card.Header><div style={{float:'left'}}>{this.props.Service.Title}</div><div style={{float:'right'}}><Card.Img variant="top" src={this.props.Service.Image} /></div></Card.Header>
          <Card.Body>
            <Row>
              <Col>
                <Card.Text>
                <span dangerouslySetInnerHTML={{ __html: this.props.Service.Intro }} />
                </Card.Text>
                <Card.Text>
                    <span dangerouslySetInnerHTML={{ __html: this.props.Service.Description}} />
                </Card.Text>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Col>
    );
  }

}