import React from 'react';
import './App.css';
import { Parallax } from 'react-parallax';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Col,  Row, Button } from 'react-bootstrap';
import { scroller, animateScroll as scroll } from 'react-scroll';
import { getContacts, getEmployees, getIntro, getSections, getServices } from './apiHelper';
import EmployeeCard from './components/EmployeeCard';
import ServiceCard from './components/ServiceCard';
// import ServiceCardLarge from './components/ServiceCardLarge';
import ContactCard from './components/ContactCard';
import IntroCard from './components/IntroCard';
import { Contact, Employee, Intro, Section, Service } from './models/models';

export interface IWebsiteProps {

}
export interface IWebsiteState {
  sections: Section[];
  intros: Intro[];
  employees: Employee[];
  services: Service[];
  contact: Contact;
  hideIcon: boolean;
}
class App extends React.Component<IWebsiteProps, IWebsiteState> {

  constructor(props: IWebsiteProps) {
    super(props);
    this.state = { hideIcon: true, sections: [], intros: [], employees: [], services: [], contact: { Title: "", City: "", Email: "", OrgNumber: "", Phone: "", PostalCode: "", Street: "" } };
  }


  private isInViewport(el: any): boolean {
    const rect = el.getBoundingClientRect();
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)

    );
  }


  private chicago = "images/chicago.jpg";;
  private ny = "https://images.fineartamerica.com/images/artworkimages/mediumlarge/1/empire-state-building-black-and-white-square-format-john-farnan.jpg";
  private logo = "/images/logo300px.png";
  private logoInv = "/images/logo_300px_inv.png";
  private icon = "/images/ikoner_liten_444.png";
  private hacker = "/images/hacking.jpg";
  private topBgImage = "/images/nvann1.jpg";
  private tree = "/images/tree.png";
  private forest = "/images/forest.jpg";
  // private contact = "/images/ekeberg.jpg";
  private contact = "/images/autumn1.jpg";

  private scrollTo = (element: string) => {
    scroller.scrollTo(element, {
      duration: 800,
      delay: 0,
      smooth: 'easeInOutQuart'
    })
  }
  private handleScroll = () => {
    const logoElement = document.getElementById("logo");
    this.setState({
      hideIcon: this.isInViewport(logoElement)
    })

  };
  async componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }
  async componentDidMount() {
    window.addEventListener('scroll', this.handleScroll, true);
    const sectionResponse = await getSections();
    const introResponse = await getIntro();
    const employeesResponse = await getEmployees();
    const servicesResponse = await getServices();
    const contactResponse = await getContacts();
    const sortedServices= servicesResponse.sort((a:any,b:any)=>{
      return a.Title.localeCompare(b.Title)
    
    });
    this.setState({ sections: sectionResponse, employees: employeesResponse, services: sortedServices, contact: contactResponse[0], intros: introResponse });
  }

  private MenuItem = (section: Section) => {
    return (<li key={section.Title}>
      <Button onClick={() => this.scrollTo(section.SectionId)} className="btn btn-sm btn-outline-dark btn-link menuBtn border-0">{section.Title}</Button>
    </li>
    );
  };

  private Services = (section: Section) => {
    if (this.state.services.length === 0) return <span></span>;
    return (
      <div style={{ minHeight: window.screen.availHeight + 20 }}>
      <Parallax blur={{ min: -25, max: 25 }} strength={600} key={section.Title}>
        {/* <div style={{ minHeight: window.screen.availHeight + 20 }} className={section.SectionId} id={section.SectionId}>
          <h2>{section.Title}</h2>
          <Row>
            {
              this.state.services.map(function (s, i) {
                return (<ServiceCardLarge Service={s} key={i}></ServiceCardLarge>);
              })
            }
          </Row>

        </div> */}
        <div className={section.SectionId} id={section.SectionId} >
          <h2>{section.Title}</h2>
          <Row>
            {
              this.state.services.map(function (s, i) {
                return (<ServiceCard Service={s} key={i}></ServiceCard>);
              })
            }
          </Row>
        </div>
      </Parallax>
      </div>
    );
  };

  private Employees = (section: Section) => {
    if (this.state.employees.length === 0) return <span></span>;
    return (
      <Parallax key={section.Title}>
        <div style={{ minHeight: window.screen.availHeight + 20 }} id={section.SectionId} className={section.SectionId}>
          <h2>{section.Title}</h2>
          <Row>
            {
              this.state.employees.map(function (emp, i) {
                return (<EmployeeCard Employee={emp} key={i}></EmployeeCard>);
              })
            }
          </Row>
        </div>
      </Parallax>
    );
  };

  private Contact = (section: Section) => {
    if (this.state.employees.length === 0) return <span></span>;
    return (<div key={section.SortOrder} style={{ minHeight: window.screen.availHeight + 20 }} >
      <ContactCard Contact={this.state.contact} BgImage={this.contact} key="contactKey"></ContactCard>
    </div>);
  };

  public render(): React.ReactElement<{}> {
    
   
    return (
      <div className="App" >
        {/* MENU */}
        <div className="topStripe" id="start">
          <div>
            <ul>
              <li style={{ minWidth: '38px', width: '38px' }}>
                <span><img src={this.icon} className="logo" alt="logo" id="icon" hidden={this.state.hideIcon} height="24px" width="24px" onClick={() => scroll.scrollToTop()} />&nbsp;</span>
              </li>
              <li>
                <Button onClick={() => scroll.scrollToTop()} aria-pressed="false" className="btn-sm btn-outline-dark btn-link menuBtn">HJEM</Button>
              </li>
              {this.state.sections.map((s, i) => {
                return this.MenuItem(s);
              })
              }
            </ul>

          </div>
        </div>
        {/* END MENU */}
        {/* <Container fluid> */}
          {/* <Row>
            <Col className="firstCol"> */}
              <div id="main">
                {/* INTRO */}
                <Parallax bgImage={this.topBgImage} blur={{ min: -5, max: 5 }} strength={200}>
                  <Row>
                    <Col>
                      <div className="logoSection" id="logo">
                        <img src={this.logo} className="logo" alt="logo" />
                      </div>
                    </Col>
                  </Row>

                  <div className="startSection" style={{ minHeight: window.screen.availHeight + 20 }}>
                    {
                      this.state.intros.map(function (intro, i) {
                        return (<IntroCard Intro={intro} Index={i} key={i}></IntroCard>);
                      })
                    }
                    
                  </div>
                </Parallax>
                {/* END INTRO */}
                {
                  this.state.sections.map((s, i) => {
                    if (s.SectionId === 'Services') {
                      return this.Services(s);
                    }
                    if (s.SectionId === 'Employees') {
                      return this.Employees(s);
                    }
                    if (s.SectionId === 'Contact') {
                      return this.Contact(s);
                    }
                    return (
                      <span></span>
                    );
                  })
                }
              </div>
            {/* </Col>
          </Row> */}
        {/* </Container> */}
      </div>
    );
  }
}

export default App;

