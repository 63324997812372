import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Intro } from '../models/models';
import Image from 'react-bootstrap/Image'
// import { scrollToBottom } from 'react-scroll/modules/mixins/animate-scroll';

export interface IIntroCardProps {
  Intro: Intro;
  Index: number;
}

export interface IIntroCardState {

}

export default class IntroCard extends React.Component<IIntroCardProps, IIntroCardState> {

  constructor(props: IIntroCardProps) {
    super(props);
    this.state = {};
  }

  public render(): React.ReactElement<IIntroCardProps> {
    const columns = this.props.Intro.Image ? 6 : 12;
    // if (this.props.Index % 2 !== 0) {
    //   return (
    //     <Row className="introCard">
    //       <Col xs={12} md={columns} lg={columns} className="introCardCol">
    //         {this.props.Intro.Image &&
    //           <Image src={this.props.Intro.Image} rounded />
    //         }
    //       </Col>
    //       <Col xs={12} md={columns} lg={columns} className="introCardCol">
    //         <Col className="introHeader">{this.props.Intro.Title}</Col>
    //         <Col><p>{this.props.Intro.Intro}</p></Col>
    //         <Col><p dangerouslySetInnerHTML={{ __html: this.props.Intro.Description }} /></Col>
    //       </Col>
    //     </Row>
    //   );
    // } else {
    return (
      // <Col xs={12} md={4} lg={12} >
      <Row className="introCard">
        <Col xs={12} md={columns} lg={columns} className="introCardCol">
          {/* <Col className="introHeader">{this.props.Intro.Title}</Col> */}
          <div className="introHeader">{this.props.Intro.Title}</div>
          <Row>
            <Col xs={3} md={3} lg={3}>&nbsp;</Col>
            <Col xs={12} md={3} lg={3} className="introIntro" ><span dangerouslySetInnerHTML={{ __html: this.props.Intro.Intro }} /></Col>
            <Col xs={12} md={3} lg={3} className="introDescription" ><span dangerouslySetInnerHTML={{ __html: this.props.Intro.Description }} /></Col>
            <Col xs={3} md={3} lg={3}>&nbsp;</Col>

          </Row>
        </Col>
        <Col xs={12} md={columns} lg={columns} className="introCardCol">
          <Image src={this.props.Intro.Image} rounded />
        </Col>
      </Row>
      // </Col>
    );
  }

  // }

}